import store from "@/store";
import Menu from "@/modules/dashboardGlobal/modules/menu/layouts/MenuLayout.vue";
import Home from "@/modules/dashboardGlobal/modules/home/views/Home.vue";

export default {
  name: "lite-dashboard",
  component: () =>
    import(/* webpackChunkName: "BranchLayout" */ "../layouts/NavBarUS.vue"),

  beforeEnter() {
    if (!store.state.login.isLogued) {
      return { name: "Login" };
    }
    return null;
  },
  children: [
    {
      path: "",
      name: "branch-home-lite",
      component: Home,
    },
    {
      path: "notifications",
      name: "branch-notifications-lite",
      component: () =>
        import(/* webpackChunkName: "Branches" */ "../views/Notifications.vue"),
    },
    {
      path: "analytics",
      name: "branch-graps-lite",
      component: () =>
        import(
          /* webpackChunkName: "Branches" */ "../../dashboardGlobal/modules/home/views/Graphs.vue"
        ),
    },
    {
      path: "analitycs/reviews",
      name: "branch-statistics-lite",
      component: () =>
        import(
          /* webpackChunkName: "Branches" */ "../../dashboardGlobal/modules/home/components/StatisticsReviews.vue"
        ),
    },
    {
      path: "menu",
      name: "branch-menu-lite",
      component: Menu,

      children: [
        {
          path: "",
          name: "",
          component: () => import("../components/menu/Details.vue"),
        },
        {
          path: "category=:category",
          name: "lite-category",
          component: () => import("../components/menu/NewCategory.vue"),
        },
        {
          path: "product=:id",
          name: "lite-product",
          component: () => import("../components/menu/ProductDetails.vue"),
        },
      ],
    },
    {
      path: "tables",
      name: "branch-tables-lite",
      component: () =>
        import(/* webpackChunkName: "Branches" */ "../views/Tables.vue"),
    },
    {
      path: "settings",
      name: "branch-settings-lite",
      component: () =>
        import(/* webpackChunkName: "BranchInfo" */ "../views/Settings.vue"),
    },
    {
      path: "marketing",
      name: "branch-analytics-lite",
      component: () =>
        import("../../dashboardGlobal/modules/home/views/Analitycs.vue"),
    },

    {
      path: "campaings",
      name: "branch-campaings-lite",
      component: () => import("../views/Settings.vue"),
    },
    {
      path: "marketing-analytics",
      name: "marketing-analytics",
      component: () =>
        import("../../dashboardGlobal/modules/home/views/Analitycs.vue"),
    },
    {
      path: "events",
      name: "branch-events-lite",
      component: () =>
        import("../../dashboardGlobal/modules/home/views/EventsMarketing.vue"),
    },
    {
      path: "create-event",
      name: "create-event-lite",
      component: () =>
        import("../../dashboardGlobal/modules/home/components/CreateEvent.vue"),
    },
    {
      path: "edit-event",
      name: "edit-event-lite",
      component: () =>
        import("../../dashboardGlobal/modules/home/components/EditEvent.vue"),
    },
    {
      path: "reservations",
      name: "branch-reservations-lite",
      component: () => import("../../liteDashboard/views/ComingSoon.vue"),
    },
  ],
};
